<template>
    <div class=container>
        <br>
        <ul class=projectGrid v-if="loaded">
            <li class=projectBox v-for="rep in repositories" :key=rep.title>
                <router-link :to="{ path: rep.url, }">
                    <img class=projIcon :src=rep.photo />
                </router-link>
                <router-link class=name :to="{ path: rep.url }">
                    {{ rep.title }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script setup>
import repositories from '@/assets/repository/repositories.json';
const loaded = repositories.length !== 0;
</script>


<style scoped>
.container {
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
}

/* ul */
.projectGrid {
    margin-top: 5vh;
    margin-bottom: 10vh;
    list-style: none;

}

/* li */
.projectBox {
    margin-top: 5vh;
    height: 200px;
    width: 1200px;
}

/* img */
.projIcon {
    width: 253px;
    height: 190px;
    border-radius: 3px;
    border: 2px solid #9c9797;
    float: left
}

/* router link */
.name {
    margin-left: 50px;
    margin-top: 80px;
    font-size: 23px;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    float: left;
}
</style>